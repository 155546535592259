@import '~@teespring/ts-components/dist/styles/_sass-includes.scss';

.main__title {
  display: flex;
  justify-content: center;
  width: 70%;
  margin-bottom: 0.5em;
  font-size: 34px;
  font-weight: bold;
  line-height: 28px;
}

.button-align {
  margin-top: 2em;
  display: flex;
  width: 70%;
  justify-content: flex-end;

  .cancel_button{
    margin-right: 1em;
    color: #6A6F72;
    user-select: none;
  }
}

.centered{
  display: flex;
  justify-content: center;
  width: 70%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  svg {
    animation: rotation 2s infinite linear;
  }
}

.DeleteTierModalButtons {
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: 3em;
    margin-right: 0.5em;
  }
}

.TierItem {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-left: 1em;
  position: relative;

  input {
    box-shadow:none;
  }

  .TierItemActions {
    :disabled {
      color: $mediumgrey;
    }
    button {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    display: flex;
    flex-direction: row;
    flex-basis: center;
  }
}
