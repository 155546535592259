@import '@teespring/ts-components/dist/styles/_sass-includes.scss';

.main__layout {
  display: flex;
}

.main__navbar {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: blue;
}

.main__content {
  height: 100vh;
  overflow-y: scroll;
  color: $darkergrey;

  h1 {
    font-size: 4em;
    margin-bottom: 2em;
  }

  font-weight: normal;
  padding-top: 1em;
  padding-left: 5em;
  background-color: $lightergrey;
  width: 100%;
  flex: 1;

  input {
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.7em;
    border: none;
    padding-left: 1.5em;
    background-color: $white;
    height: 3.5em;
    margin-right: 0.5em;
  }
}
