.table {
  border-collapse: collapse;
  width: 70%;
  padding: 0.5px;
  margin-top: 1em;

  tbody tr:nth-child(odd) {
    background-color: #F6F6F9;
  }

  td, th {
    padding: 1em 0.5em;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: #0A0917;
    font-weight: bold;
  }
}
